<template>
  <div>
    <v-container class="mkt-content-container mt-10">
      <v-row>
        <v-col cols="5">
        <span class="headline font-weight-bold">
          Bem-vindo ao markeplace Proxpect
        </span>
          <div class="mt-3">
            Veja no vídeo ao lado como utilizar a Proxpect!
          </div>
          <div class="mt-3">
            <v-btn large
                   link
                   to="/home"
                   color="primary"
                   class="rounded-card-small normal-btn-text elevation-0">
              Começar a navegar
            </v-btn>
          </div>
        </v-col>
        <v-col cols="7">
          <iframe width="550" height="350"
                  src="https://www.youtube.com/embed/e7Qf9khXlxc?controls=0">
          </iframe>
        </v-col>
      </v-row>
    </v-container>
    <div class="primary">
      <v-container class="mkt-content-container-internal mt-12">
        <v-row class="mt-5">
          <v-col cols="12">
            <px-vantagens title-color="white--text" text-color="white--text"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="mkt-content-container mt-10">
      <px-home-page-parceiros/>
      <px-home-page-block class="mt-5"
                          query="promocao.ativo is true and disponivel is true order by random"
                          title="Promoções"
                          :qtd-ofertas="30"/>
      <px-landing-page-whatsapp :banner-cadastro-text-whats="bannerCadastroTextWhats"/>
    </v-container>


  </div>
</template>

<script>
import Vantagens from '@/modules/institucional/Vantagens.vue';
import HomePageParceiros from '@/modules/home-page/HomePageParceiros.vue';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageWhatsapp from '@/modules/landing-page/LandingPageWhatsapp.vue';

export default {
  data() {
    return {
      bannerCadastroTextWhats: 'Dúvida? Fale com nosso time! Será um prazer te ajudar!',
    };
  },
  components: {
    pxVantagens: Vantagens,
    pxHomePageParceiros: HomePageParceiros,
    pxHomePageBlock: HomePageBlock,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
